.custom-scrollbar {
  overflow-y: auto;
}

/* width */
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 2px;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #02a4ff;
  border-radius: 10px;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #01629b;
}
