body {
    font-family: 'Roboto', sans-serif !important;
}

p {
    margin: 0 !important;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.flex {
    display: flex;
}

.grow {
    flex-grow: 1;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-700 {
    font-weight: 700;
}

.font-size-14px {
    font-size: 14px;
}

.font-size-15px {
    font-size: 15px;
}

.font-size-18px {
    font-size: 18px;
}

.font-size-28px {
    font-size: 28px;
}

.sidebar {
    padding: 24px;
    width: 280px;
}

.sidebar .nav-link {
    font-weight: 400 !important;
    color: #828282 !important;
    padding: 8px 0 8px 0;
    margin: 0;
}

.sidebar .nav-link.active {
    font-weight: 700 !important;
    color: #02A4FF !important;
}

.sidebar-logo {
    width: 64px;
}

.checkbox-tabla {
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    border: 1px solid #BDBDBD !important;
    border-radius: 3px;
}

/* .mr-3 {
    margin-right: 1rem !important;
}

.mr-4 {
    margin-right: 1.5rem !important;
}

.mr-5 {
    margin-right: 3rem !important;
}

.ml-1 {
    margin-left: 0.25rem !important;
}

.ml-3 {
    margin-left: 1rem !important;
}

.ml-5 {
    margin-left: 3rem !important;
}

.pr-3 {
    padding-right: 1rem !important;
}

.pb-3 {
    padding-bottom: 1rem !important;
}

.pl-3 {
    padding-left: 1rem !important;
} */

.pl-10px {
    padding-left: 10px;
}

.pl-20px {
    padding-left: 20px;
}

.border-bottom-1 {
    border-bottom: 1px solid #E1E1E1;
}

.mt-48px {
    margin-top: 48px !important;
}

.ml-24px {
    margin-left: 24px;
}

.p-12px {
    padding: 12px;
}

.p-16px {
    padding: 16px;
}

.px-24px {
    padding: 0 24px !important;
}

.pxy-1612 {
    padding: 12px 16px 12px 16px;
}

.pxy-126 {
    padding: 6px 12px;
}

.pxy-84 {
    padding: 4px 8px;
}

.border-black {
    border: 1px solid black;
}

.border-radius-4px {
    border-radius: 4px;
}

.border-radius-8px {
    border-radius: 8px;
}

.border-radius-18px {
    border-radius: 18px;
}

.border-none {
    border: 0;
}

.border-gray {
    border: 1px solid #E1E1E1;
}

.background-primary {
    background: #02A4FF;
}

.background-color-primary {
    background-color: #02A4FF;
}

.background-grey-10 {
    background: #F9F9F9;
}

.background-color-grey-10 {
    background: #F9F9F9;
}

.color-white {
    color: #FFF;
}

.rotate-45 {
    transform: rotate(45deg);
}

.w-300px {
    width: 300px;
}

.text-left {
    text-align: left !important;
}

.btn.btn-primary {
    color: #fff;
    background-color: #36A4F9;
    border-color: #36A4F9;
}

form label {
    font-weight: 700;
    font-size: 15px;
    color: #5F5F5F;
}

.required {
    color: red;
}

.modal-header {
    border-bottom: 0 !important;
}

.modal-title.h4 {
    font-weight: 500;
    font-size: 20px;
}

.border-debug {
    border: 2px solid red;
}