@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    height: 100%;
}

body {
    height: 100%;
    font-family: Roboto;
}

#root {
    height: 100%;
}